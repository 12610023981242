import getRoute from "~/utilities/configs/apiMap";
import { unitPageStore } from "~/store/unitPage";
import type { PropertyItem } from "~/types/search-page-items";
import {
  getLocalStorage,
  writePersistentLocalStorage,
} from "~/services/LocalStorage/localStorage";
import { Api } from "~/services/swagger/Api";
import { updateUnitPageSeo } from "~/services/SEO/updateUnitPageSeo";

export default defineNuxtRouteMiddleware(async (to) => {
  const { id } = to.params;

  if (!id) {
    return navigateTo("/404");
  }

  try {
    const config = useRuntimeConfig();
    const baseUrl = config.public.baseURL as string;
    const setUrl =
      baseUrl + getRoute({ endpont: "get_estate", id: id as string });
    const response = await useFetch(setUrl, {
      headers: {
        Authorization: `Bearer ${useCookie("acc_token").value}`,
      },
    });
    if (!response.data.value || response.error.value) {
      return navigateTo("/404");
    }
    const useUnitpageStore = unitPageStore();
    useUnitpageStore.$state.unitPageData = response.data.value as PropertyItem;
    updateUnitPageSeo(
      useUnitpageStore.$state.unitPageData,
      useUnitpageStore.$state.unitPageData.id as string
    );
    useUnitpageStore.getAllTokens();
    nextTick(() => {
      setVisitMetrics();
    });
  } catch (error) {
    return navigateTo("/404");
  }
});

const setVisitMetrics = async () => {
  const apiClient = new Api();
  const today = new Date();
  const useUnitpageStore = unitPageStore();
  const pageId = useUnitpageStore.$state.unitPageData?.id || "";

  let metrics: {
    "24h": number;
    allTime: number;
  } = useUnitpageStore.$state.unitPageData?.metrics || {
    "24h": 0,
    allTime: 0,
  };

  // Получаем информацию о посещениях из локального хранилища
  const visitData = getLocalStorage("unit_page_visit");

  if (!visitData) {
    // Если пользователь зашел на страницу первый раз за сегодня или не было никаких записей
    writePersistentLocalStorage("unit_page_visit", {
      date: today,
      pageIds: [pageId],
    });
    metrics = {
      "24h": metrics["24h"] + 1,
      allTime: metrics.allTime + 1,
    };
    // Отправляем обновленные метрики на бэкэнд
    apiClient.api.estatesControllerUpdateMetrics(pageId);
  } else {
    const visitDate = new Date(visitData.date);

    // Проверяем, является ли текущая дата новой
    if (visitDate.toDateString() !== today.toDateString()) {
      // Обновляем дату и записываем текущую страницу как посещенную
      writePersistentLocalStorage("unit_page_visit", {
        date: today,
        pageIds: [pageId],
      });
      metrics = {
        "24h": metrics["24h"] + 1,
        allTime: metrics.allTime + 1,
      };
      // Отправляем обновленные метрики на бэкэнд
      apiClient.api.estatesControllerUpdateMetrics(pageId);
    } else if (!visitData.pageIds.includes(pageId)) {
      // Если текущая дата совпадает, но страница еще не была посещена
      visitData.pageIds.push(pageId);
      writePersistentLocalStorage("unit_page_visit", visitData);
      metrics = {
        "24h": metrics["24h"] + 1,
        allTime: metrics.allTime + 1,
      };
      // Отправляем обновленные метрики на бэкэнд
      apiClient.api.estatesControllerUpdateMetrics(pageId);
    }
  }
};
