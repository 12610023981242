import type { PropertyItem } from "~/types/search-page-items";
export const updateUnitPageSeo = (
  unitPageData: PropertyItem | undefined,
  pageId: string
) => {
  const getUnitPhotoLink =
    unitPageData?.files.find((photo) => photo.type === "image")?.file.url ||
    "https://www.rwa-estate.com/hero.png";
  const descriptions =
    "Browse RWA Estate's tokenized real estate listings. Discover property prices, investment returns, token availability, and more. Explore trending properties, value growth, and investment opportunities with real-time data.";
  const descriptionUnit = `Explore ${unitPageData?.name} on RWA Estate. View property details, investment calculator, and token availability for purchase`;
  useSchemaOrg([
    defineWebSite({
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "RWA Estate",
      url: "https://www.rwa-estate.com",
    }),
    definePlace({
      "@context": "https://schema.org",
      "@type": ["Accommodation", "Place"],
      name:
        unitPageData?.name + " | RWA Estate - Tokenized Real Estate Investment",
      description: descriptionUnit,
      address: {
        "@type": "PostalAddress",
        addressLocality: unitPageData?.countryId ?? "",
        addressRegion: "World",
        postalCode: "",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: unitPageData?.coords[0] ?? "",
        longitude: unitPageData?.coords[1] ?? "",
      },
      url: "https://www.rwa-estate.com/search/" + pageId,
      sku: `rwaestate:${unitPageData?.name}`,
      mpn: `rwaestate:${unitPageData?.name}`,
      maximumAttendeeCapacity: 18,
      numberOfRooms: 7,
      offers: {
        availability: "http://schema.org/InStock",
        price: unitPageData?.priceUSD,
        priceCurrency: unitPageData?.currency ?? "USD",
        priceSpecification: {
          "@type": "UnitPriceSpecification",
          referenceQuantity: {
            "@type": "QuantitativeValue",
            value: 1,
            unitCode: "DAY",
          },
        },
        priceValidUntil: "2025-09-05T12:00:00+08:00",
        url: "https://www.rwa-estate.com/search/" + pageId,
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: unitPageData?.rating ?? 5,
        bestRating: 5,
        reviewCount: 3,
      },
      image: getUnitPhotoLink,
      review: [
        {
          "@type": "Review",
          author: "Jessie",
          datePublished: "2023-08-25",
          reviewBody: "The house is big and the pool is nice...",
          reviewRating: {
            "@type": "Rating",
            bestRating: 5,
            ratingValue: 5,
            worstRating: 1,
          },
        },
      ],
    }),
  ]);

  useSeoMeta({
    ogTitle:
      unitPageData?.name + " | RWA Estate - Tokenized Real Estate Investment",
    ogDescription: descriptionUnit,
    keywords: [
      ...unitPageData!.name.split(" "),
      "Tokenized Real Estate Investment",
      "Property Investment",
      "Tokenized Property",
      "Real Estate Tokens for Purchase",
      `Investment Property in ${unitPageData?.location}`,
    ].join(", "),
    ogImage: getUnitPhotoLink,
    ogUrl: "https://www.rwa-estate.com/search/" + pageId,
    twitterCard: "summary_large_image",
    twitterTitle:
      unitPageData?.name + " | RWA Estate - Tokenized Real Estate Investment",
    twitterDescription: descriptionUnit,
    twitterImage: getUnitPhotoLink,
  });

  useHead({
    title:
      unitPageData?.name + " | RWA Estate - Tokenized Real Estate Investment",
    meta: [
      {
        property: "og:type",
        content: "place",
      },
    ],
  });
};

// const asds = {
//   "@context": "http://schema.org",
//   "@graph": [
//     {
//       "@type": "Organization",
//       name: "Villa Finder",
//       url: "https://www.villa-finder.com",
//       legalName: "Villa Finder Pte Ltd",
//       description: "Villa Finder Description",
//       logo: "https://new-imagx.s3.ap-southeast-2.amazonaws.com/Logos+for+structured+data/VFF.webp",
//       sameAs: [
//         "https://www.facebook.com/villafinder",
//         "https://www.instagram.com/villafinder/",
//         "https://www.youtube.com/@villafinder",
//         "https://www.linkedin.com/company/villafinder/",
//       ],
//       email: "contact@villa-finder.com",
//       foundingDate: "2012",
//       taxID: "200718423H",
//     },
//     {
//       "@type": "BreadcrumbList",
//       itemListElement: [
//         {
//           "@type": "ListItem",
//           position: 1,
//           item: {
//             "@type": "WebPage",
//             "@id": "/",
//             name: "Home",
//           },
//         },
//         {
//           "@type": "ListItem",
//           position: 2,
//           item: {
//             "@type": "WebPage",
//             "@id": "/en/thailand",
//             name: "Thailand",
//           },
//         },
//         {
//           "@type": "ListItem",
//           position: 3,
//           item: {
//             "@type": "WebPage",
//             "@id": "/en/south-thailand",
//             name: "South Thailand",
//           },
//         },
//         {
//           "@type": "ListItem",
//           position: 4,
//           item: {
//             "@type": "WebPage",
//             "@id": "/en/phuket",
//             name: "Phuket",
//           },
//         },
//         {
//           "@type": "ListItem",
//           position: 5,
//           item: {
//             "@type": "WebPage",
//             "@id": "/en/south-phuket",
//             name: "Nai Harn, Rawai & Chalong",
//           },
//         },
//         {
//           "@type": "ListItem",
//           position: 6,
//           item: {
//             "@type": "WebPage",
//             "@id": "/en/nai-harn-beach",
//             name: "Nai Harn",
//           },
//         },
//         {
//           "@type": "ListItem",
//           position: 7,
//           item: {
//             "@type": "WebPage",
//             "@id": "/en/7-bedroom-nai-harn-beach",
//             name: "7 bedrooms",
//           },
//         },
//         {
//           "@type": "ListItem",
//           position: 8,
//           item: {
//             "@type": "WebPage",
//             "@id": "/en/nai-harn-beach/villa-pearl",
//             name: "Villa Pearl",
//           },
//         },
//       ],
//     },
//     {
//       "@type": ["Accommodation", "Product"],
//       name: "Villa Pearl",
//       description: "Villa Pearl - Nai Harn - 7 bedrooms | Villa Finder",
//       address: {
//         "@type": "PostalAddress",
//         addressLocality: "Nai Harn beach",
//         addressRegion: "World",
//         postalCode: "83130",
//       },
//       geo: {
//         "@type": "GeoCoordinates",
//         latitude: 7.78553,
//         longitude: 98.30729,
//       },
//       url: "/en/villa/nai-harn-beach/villa-pearl",
//       sku: "villafinder:villa-pearl",
//       mpn: "villafinder:villa-pearl",
//       maximumAttendeeCapacity: 18,
//       numberOfRooms: 7,
//       offers: {
//         availability: "http://schema.org/InStock",
//         price: 600,
//         priceCurrency: "USD",
//         priceSpecification: {
//           "@type": "UnitPriceSpecification",
//           referenceQuantity: {
//             "@type": "QuantitativeValue",
//             value: 1,
//             unitCode: "DAY",
//           },
//         },
//         priceValidUntil: "2024-09-05T12:00:00+08:00",
//         url: "/en/villa/nai-harn-beach/villa-pearl",
//       },
//       aggregateRating: {
//         "@type": "AggregateRating",
//         ratingValue: 10,
//         reviewCount: 3,
//         bestRating: 10,
//       },
//       image:
//         "https://cf-img.villa-finder.com/cf/m/villas/villa-pearl/villa-pearl-game-room-004-596839f918dc2.jpg",
//       review: [
//         {
//           "@type": "Review",
//           author: "Jessie",
//           datePublished: "2023-08-25",
//           reviewBody:
//             "The house is big and the pool is nice. The housekeeper's Thai cuisine is very authentic. 15 mins walk to Nai Harn Beach, 10 mins to the nearest supermarket. I rented a motorbike. The housekeeper can help us book activities on the island, use cars, massages. The whole trip was so relaxing because of the good accommodation!",
//           reviewRating: {
//             "@type": "Rating",
//             bestRating: 10,
//             ratingValue: 10,
//             worstRating: 1,
//           },
//         },
//       ],
//     },
//     {
//       "@type": "WebSite",
//       name: "Villa Finder",
//       url: "https://www.villa-finder.com",
//     },
//   ],
// };
